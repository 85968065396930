// ToDo: Formular über CloudFlare-Captcha absichern !!!!!

import './App.css';
import React, { useRef, useState, useEffect } from 'react';
import FlexibleDigitInput, { RESET_DIGITS } from './FlexibleDigitInput';
import { eventEmitter } from './EventDispatcher';
import { api } from '@linkware/api'
import { getInvalidInputDelay, resetInvalidInputDelay, adjustInvalidPinDelay } from './inputDelay.js'

const STEP_GET_PIN = 0
const STEP_GET_SECRET = 1
const STEP_DOWNLOAD = 2

// Download-Step nach 2 Minuten beenden 
const AUTO_RESTART_AFTER_MS = 2 * 60000

function App() {
  const digitInputRef = useRef(null)
  const restartButtonRef = useRef(null)

  // Zum Testen des Download-Steps auskommentieren
  // const [step, setStep] = useState(STEP_DOWNLOAD)
  // const [filename, setFilename] = useState("DESKERIO-GMX123FGH.exe")

  const [step, setStep] = useState(STEP_GET_PIN)
  const [filename, setFilename] = useState(null)

  const [errorValue, setErrorValue] = useState(null)
  const [pin, setPin] = useState(null);
  const [validating, setValidating] = useState(false)


  // Download-Step nach einiger Zeit beenden und wieder auf Anfang gehen
  useEffect(() => {
    console.log("NOW!");
    if(step === STEP_DOWNLOAD) {
      setTimeout(() => { handleRestart() }, AUTO_RESTART_AFTER_MS)
    }
  }, [step]);


  // Leerzeichen in PIN einfügen
  function formatErrorPin(input) {
    if (input.length !== 9)
      return input
    return input.slice(0, 3) + " " + input.slice(3, 6) + " " + input.slice(6);
  }
  

  // Nach erfolgreichem Download die Möglichkeit anbieten von vorne zu beginnen
  function handleRestart()
  {
    setPin(null)
    setErrorValue(null)
    setValidating(false)
    eventEmitter.emit(RESET_DIGITS);
    // resetInvalidPinDelay()
    setStep(STEP_GET_PIN)
  }


  // Download mit entsprechender PSID-Namenserweitertung starten
  function startDownload(psid) {
    if (psid) {
      setFilename('DESKERIO--' + psid + '.exe')
      const url = `https://get.deskerio.com/windows/DESKERIO.exe?psid=${psid}`;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } 
  };


  // customId (join.deskerio.com:müller-ag) abhängig von Host und Path zurückgeben
  function getCustomId () {
    let host = window.location.hostname.toLowerCase()
    let handle = ''
    let customId = host
    if(window.location.pathname !== '/') {
      const path = decodeURIComponent(window.location.pathname)
      const parts = path.replace(/^\/+/, '').split('/');
      if(parts.length>0) {
        handle = parts[0]
        customId += ':' + handle;
      }
    }          
    return customId
  }


  // Eingegebene Session-PIN bzw. Session-Secret validieren
  async function validateInput(value)
  {
    let data = {}

    setValidating(true)
    setErrorValue(null)

    if(step === STEP_GET_PIN) {
      data.tsid = value
      setPin (value)

    } else if (step === STEP_GET_SECRET) {

      data.tsid = pin
      data.spin = value
    }    

    setTimeout(() => {                       
      //api.get('/client/echo?echo=HELLO')
      api.post(`/client/v1/customservices/${getCustomId()}/join/`, data)
        .then (function(response) {     
          const data = response.data.result;

          if(data.invalidSecret) {
            // Eventuelles Delay nach Falscheingabe TSID zurücksetzen
            if(step === STEP_GET_PIN) {
              resetInvalidInputDelay()
            }
            else
              setErrorValue(value)

            setStep(STEP_GET_SECRET)
            eventEmitter.emit(RESET_DIGITS);
            return

          } else {

            setStep (STEP_DOWNLOAD) 
            resetInvalidInputDelay()           
            startDownload(data.psid)
             
            // Fokus zeitversetzt auf Restart-Button setzen 
            setTimeout(() => {
              restartButtonRef.current?.focus();
            }, 50)
          }
        })
        .catch(function(error) {
          setErrorValue(value)
          if(step === STEP_GET_PIN) 
            setPin(null)

          eventEmitter.emit(RESET_DIGITS);
        })
      .then(function() {
        setValidating(false)
      })
    }, getInvalidInputDelay())

    adjustInvalidPinDelay();
  }


  if(step === STEP_GET_PIN)
    return(
      <>
        <div className="App">

          {!validating && !errorValue && (
            <div className="ready-msg-container">
              <div className="ready-msg">
                Ready
              </div> 
            </div>       
          )}

          {validating && (
            <div className="validating-msg-container">
              <div className="validating-msg">
                Validating...
              </div> 
            </div>       
          )}

          {errorValue && (
            <div className="error-msg-container">
              <div className="error-msg">
                PIN&nbsp;<b>{formatErrorPin(errorValue)}</b>&nbsp;- No session found
              </div> 
            </div>       
          )}
          <FlexibleDigitInput 
            ref={digitInputRef} 
            digits={9} 
            prompt={'To join a session, please enter the provided session PIN:'}
            validatePin={validateInput}
            validating={validating}/>   

        </div> 
      </>
  )

  if(step === STEP_GET_SECRET)
    return(
      <>
        <div className="App">

          {!validating && !errorValue && (
            <div className="ready-msg-container">
              <div className="ready-msg">
                PIN&nbsp;<b>{formatErrorPin(pin)}</b>&nbsp;- Session found
              </div> 
            </div>       
          )}

          {validating && (
            <div className="validating-msg-container">
              <div className="validating-msg">
                Validating...
              </div> 
            </div>       
          )}

          {errorValue && (
            <div className="error-msg-container">
              <div className="error-msg">
                PIN&nbsp;<b>{formatErrorPin(pin)}</b>&nbsp;- Invalid secret (<b>{formatErrorPin(errorValue)}</b>)
              </div> 
            </div>       
          )}
          <FlexibleDigitInput 
            ref={digitInputRef} 
            digits={4} 
            prompt={'Please enter the required session secret:'}
            validatePin={validateInput}
            validating={validating}/>   

        </div> 
      </>
  )

  if(step === STEP_DOWNLOAD)
    return (

      <div className="App">      
      
        <div className="ready-msg-container">
          <div className="ready-msg">
            Download has been started
          </div> 
        </div>       

        <div>
          <svg className="download-svg"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
          </svg>            
        </div>

        <div className="download-name-container">
          <div className="download-name">
            <img className="appicon" src="/images/appicon.svg" alt="App-Icon"></img>
            <div><b>{filename}</b></div>
          </div>          
        </div>

        <div className="security-details">
          Show Security Details 
        </div>

        <div className="run-app-info">
          <b>
            Open this App using your browser's download manager (Ctrl-J). <br></br>
            Or launch it from your downloads folder.
          </b> 
        </div>

        {/* <b>Überprüfen Sie Ihren Download-Manager für den Fortschritt.</b>  */}
        {/* <button ref={restartButtonRef} className="button-join" onClick={handleRestart}>Restart</button> */}
        {/* <div>
          <button ref={restartButtonRef} className="button-join" onClick={handleRestart}>Join another session</button>
        </div> */}

      </div>  
    )
}

export default App;
