import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { apiInit, api } from '@linkware/api'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { getCustomId, camelToDash } from './common';

// Sollte direkt in linkware/api erledigt werden:
// https://stackoverflow.com/questions/67804053/uncaught-referenceerror-buffer-is-not-defined-in-react
import { Buffer } from 'buffer'; 
if (!window.Buffer) { window.Buffer = Buffer; }

const CONFIG_ROOT = "config-root"
const CONFIG_NOT_FOUND_ATTR = 'data-no-config'

if(process.env.NODE_ENV === "development") { 
  //apiInit("https://localhost.deskerio.com:7071"); 
  apiInit("https://api.deskerio.com"); 
} else {
  apiInit("https://api.deskerio.com"); 
}
  

// React Dev Tools in Production deaktivieren 
// https://github.com/fvilers/disable-react-devtools
// https://www.npmjs.com/package/@fvilers/disable-react-devtools
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  disableReactDevTools();
}


function customizeStyle(source, attribName)
{
  if(source) {
    const attr = source.getAttribute('data-' + attribName)
    if(attr)
      document.documentElement.style.setProperty('--' + attribName, attr);
  }
}


function customize() {
  let configFound = false
  let configRoot = document.getElementById(CONFIG_ROOT)
  if(configRoot) {

    // Wenn zu customId keine Daten vorhanden sind, setzt worker das Attribut "data-co-config"
    configFound = (configRoot.getAttribute(CONFIG_NOT_FOUND_ATTR) === null)
    if(configFound) {
      customizeStyle(configRoot,'body-background')
      customizeStyle(configRoot,'pin-cursor-color')
      customizeStyle(configRoot,'pin-active-background')
      customizeStyle(configRoot,'pin-inactive-color')
      customizeStyle(configRoot,'pin-inactive-background')
  
      // Ggf. Logo und Organizationsname tauschen
      const headerContainer = document.getElementById("header-container")
      if(headerContainer)
      {
        let flexDirection = "row"
        if(configRoot.getAttribute("data-header-reverse") == 1)
          flexDirection = "row-reverse"
        headerContainer.style.flexDirection = flexDirection
      }
  
      // Organisationsnamen setzen
      const organization = document.getElementById("organization")
      if(organization)
        organization.innerText = configRoot.getAttribute("data-organization")

      const copyright = document.getElementById("copyright")
      if(copyright)
      {        
        const year = new Date().getFullYear();
        copyright.innerText = `Copyright © ${year} by Linkware GmbH. All rights reserved.`
      }

    }
  }

  // Alles gepachted, jetzt entsprechende divs anzeigen
  document.getElementById("header").style.display = "flex"
  document.getElementById("footer").style.display = "flex"  

  if(configFound)
    document.getElementById("content").style.display = "flex"
  else
    document.getElementById("noconfig").style.display = "flex"

  // Jetzt den ganzen Body anzeigen
  // ToDo brauchen wir dann noch oben die header/footer - Steuerung?
  document.body.style.display = "flex"
}


if(process.env.NODE_ENV === "development") { 
  
  // Im Dev-Mode patchen wir uns hier selbst die Daten zurecht. 

  let configRoot = document.getElementById(CONFIG_ROOT)  
  if(configRoot) {
    
    // Auf true setzen für rein lokale Enwicklung ohne API-Aufruf 
    if(false) {
      configRoot.setAttribute('data-body-background', '#345454')
  
      configRoot.setAttribute('data-Pin-Cursor-color', 'red')
      configRoot.setAttribute('data-pin-active-background', 'blue')
      configRoot.setAttribute('data-pin-inactive-color', 'blue')
      configRoot.setAttribute('data-pin-inactive-background', 'yellow')
     
      configRoot.setAttribute('data-header-reverse', '1')
      configRoot.setAttribute('data-organization', 'Linkware GmbH')
      configRoot.setAttribute('data-organization', 'Linkware GmbH')

      customize()
      
    } else {

      // Settings für customId von API abrufen
      api.get(`/client/v1/customservices/${getCustomId()}/siteconfig/`)
        .then (function(response) {     
          const config = response.data.result;
          console.log("CONFIG")
          console.log(config)
  
          const props = Object.keys(config);
          for (let prop of props) {
            console.log(prop + ': ' + config[prop]);
            configRoot.setAttribute('data-' + camelToDash(prop), config[prop])                    
          }      
        })
        .catch(function(error) {
          console.log("ERROR!!!!!!")
          console.log({error})
          configRoot.setAttribute(CONFIG_NOT_FOUND_ATTR, '1')
        })
      .then(function() {        
        customize()

        // Erst jetzt rendern, ansonsten erhält FlexibleDigitInput keinen 
        // Fokus nach dem Page-Load
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
          <React.StrictMode>
            <App />
          </React.StrictMode>
        );
      })
    }
  }
} else {

  // Production-Mode 
  customize()

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
