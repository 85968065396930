// Schlüssel für die XOR-Verschlüsselung
const ENCRYPTION_KEY = 1248362722;
const ENCRYPTED_DELAY_KEY = 'zis782912kls'
const TIMESTAMP_KEY = 'cobi42762ntr'
const DELAY_TTL = 60000
const INITIAL_DELAY = 500
const MAX_DELAY = 32000


// XOR-Verschlüsselungsfunktion
function xorEncryptDecrypt(value, key) {
    return value ^ key;
}


export function resetInvalidInputDelay() {
	setInvalidPinDelay(INITIAL_DELAY)
}


export function adjustInvalidPinDelay() {
	const delay = getInvalidInputDelay();
	if(delay < MAX_DELAY)
		setInvalidPinDelay(delay * 2)
	else
		setInvalidPinDelay(delay)
}


function setInvalidPinDelay(delay) {
	// Verschlüsseln des Delay-Wertes
	const encryptedDelay = xorEncryptDecrypt(delay, ENCRYPTION_KEY);

	// Speichern des verschlüsselten Wertes im LocalStorage
	localStorage.setItem(ENCRYPTED_DELAY_KEY, encryptedDelay);

	// Speichern des aktuellen Zeitstempels im LocalStorage
	const timestamp = Date.now();
	localStorage.setItem(TIMESTAMP_KEY, timestamp);
}


export function getInvalidInputDelay() {
	const encryptedDelay = localStorage.getItem(ENCRYPTED_DELAY_KEY);
	const timestamp = localStorage.getItem(TIMESTAMP_KEY);

	// Überprüfen, ob der Zeitstempel existiert
	if (timestamp) {
			const currentTime = Date.now();
			const timeDiff = currentTime - timestamp;

			// Wenn der Zeitstempel älter als DELAY_TTL ist, INITIAL_DELAY zurückgeben
			if (timeDiff > DELAY_TTL) {
					return INITIAL_DELAY;
			} else {
					// Den verschlüsselten Wert entschlüsseln und als Integer zurückgeben
					return xorEncryptDecrypt(parseInt(encryptedDelay), ENCRYPTION_KEY);
			}
	} else {
			// Wenn kein Zeitstempel vorhanden ist, INITIAL_DELAY zurückgeben
			return INITIAL_DELAY;
	}
}